import React, { Component } from "react";
import Texts from "../../../utils/lang-utils";
import urls from "../../../areas/main/assets/urls";
import './Pricing.scss';

export default class Pricing extends Component {
  constructor() {
    super();
    this.state = {
      month: false,
      year: true
    };
  }

  handleToggle = () => {
    const month = this.state.month;
    const year = this.state.year;

    if (month) {
      this.setState({ year: true, month: false });
    }
    if (year) {
      this.setState({ year: false, month: true });
    }
  };

  render() {
    return (
      <section className="pricing-one" id="pricing">
        <div className="container">
          <div className="block-title text-center">
            <br></br>
            <h1 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.Pricing.Title}}>
            </h1>
          </div>
          <ul
            className="list-inline text-center switch-toggler-list"
            id="switch-toggle-tab"
          >
            <li className={this.state.month ? "month active" : "month"}>
              <a href="#none">{Texts.Pricing.Monthly}</a>
            </li>
            <li>
              <div
                className={this.state.year ? "switch off" : "switch on"}
                role="button"
                tabIndex="-1"
                onClick={this.handleToggle}
                onKeyDown={this.handleToggle}
              >
                <span className="slider round"></span>
              </div>
            </li>
            <li className={this.state.year ? "year active" : "year"}>
              <a href="#none">{Texts.Pricing.Yearly}</a>
            </li>
          </ul>
          <div className="tabed-content">
              <div id="year">
                <div className="row pricing-one__price-row">
                  <div
                    className="col-lg-4 animated fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h2><a href={urls.qrMenu} dangerouslySetInnerHTML={{ __html: Texts.Pricing.QrMenu.Title }}></a></h2>
                      <h3>{Texts.Pricing.QrMenu[`Price${this.state.year? 'Year': 'Month' }`]}</h3>
                      <p>{Texts.Pricing.QrMenu.PricePer}</p>
                      <span className="tag-line" dangerouslySetInnerHTML={{ __html: Texts.Pricing.QrMenu.Description }}></span>
                      <p className="explanation" dangerouslySetInnerHTML={{ __html: Texts.Pricing.QrMenu.Explanation }} />
                      <ul className="list-unstyled">
                        {Texts.Pricing.QrMenu.Features.split(',').map(f=><li>{f}</li>)}
                        <li><a href={urls.qrMenu}>{Texts.Pricing.SeeAllFeatures}</a></li>
                      </ul>
                      <a href={urls.onboarding} className="thm-btn pricing-one__btn">
                        <span>{Texts.Pricing.QrMenu.CTAText}</span>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 animated fadeInDown"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h2><a href={urls.qrOrdering} dangerouslySetInnerHTML={{ __html: Texts.Pricing.QROrdering.Title }}></a></h2>
                      <h3>{Texts.Pricing.QROrdering[`Price${this.state.year? 'Year': 'Month' }`]}</h3>
                      <p>{Texts.Pricing.QROrdering.PricePer}</p>
                      <span className="tag-line" dangerouslySetInnerHTML={{ __html: Texts.Pricing.QROrdering.Description }}></span>
                      <p className="explanation" dangerouslySetInnerHTML={{ __html: Texts.Pricing.QROrdering.Explanation }} />

                      <ul className="list-unstyled">
                        {Texts.Pricing.QROrdering.Features.split(',').map(f=><li>{f}</li>)}
                        <li><a href={urls.qrOrdering}>{Texts.Pricing.SeeAllFeatures}</a></li>
                      </ul>
                      <a href={urls.onboarding} className="thm-btn pricing-one__btn">
                        <span>{Texts.Pricing.QROrdering.CTAText}</span>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 animated fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h2><a href={urls.apps} dangerouslySetInnerHTML={{ __html: Texts.Pricing.Apps.Title }}></a></h2>
                      <h3>{Texts.Pricing.Apps[`Price${this.state.year? 'Year': 'Month' }`]}</h3>
                      <p>{Texts.Pricing.Apps.PricePer}</p>
                      <span className="tag-line" dangerouslySetInnerHTML={{ __html: Texts.Pricing.Apps.Description }}></span>
                      <p className="explanation" dangerouslySetInnerHTML={{ __html: Texts.Pricing.Apps.Explanation }} />

                      <ul className="list-unstyled">
                        {Texts.Pricing.Apps.Features.split(',').map(f=><li>{f}</li>)}
                        <li><a href={urls.apps}>{Texts.Pricing.SeeAllFeatures}</a></li>
                      </ul>
                      <a href={urls.onboarding} className="thm-btn pricing-one__btn">
                        <span>{Texts.Pricing.Apps.CTAText}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }
}
